<template>
  <div></div>
</template>

<script>
import { ROUTE_NAME } from '@/constants/route.constants'
import { SHARED } from '@/constants/constants'
export default {
  name: 'contactVerification',
  created() {
    this.initializeContactVerification()
  },
  methods: {
    initializeContactVerification() {
      this.$router.push({
        name: ROUTE_NAME.HOME,
        params: {
          action: SHARED.INFO_VERIFICATION,
          memberCode: this.$route.query.membercode,
          otp: this.$route.query.otp,
          type: this.$route.query.type,
          contact: this.$route.query.contact,
          lang: this.$route.params.lang
        }
      })
    }
  }
}
</script>
